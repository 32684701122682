* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* variables for the colors */
:root {
  --honeydew: #eaf2e3;
  --fluorescent-cyan: #61e8e1;
  --bittersweet: #f25757;
  --maize: #f2e863;
  --naples-yellow: #f2cd60;
}

.itim-regular {
  font-family: "Itim", cursive;
  font-weight: 400;
  font-style: normal;
}

.pacifico-regular {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}

.bracket-list {
  min-height: 100vh;
  background-color: var(--fluorescent-cyan);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bracket-list-heading {
  padding: 25px 0px;
  font-size: 70pt;
  word-wrap: break-word;
  text-align: center;
  color: var(--bittersweet);
  text-shadow: 3px 3px 0 var(--naples-yellow), -3px 3px 0 var(--naples-yellow),
    -3px -3px 0 var(--maize), 3px -3px 0 var(--maize);
}

.bracket-list-subheading {
  font-size: 40pt;
  word-wrap: break-word;
  text-align: center;
  width: 100%;
  background-color: var(--bittersweet);
  color: var(--fluorescent-cyan);
  padding: 25px 0px;
}

.tournament-rules-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--naples-yellow);
  color: var(--bittersweet);
  font-weight: bold;
  width: 100%;
  padding: 25px 0px;
}

.tournament-rules-container ul {
  padding: 0px 25px;
}

.tournament-rules-container h3 {
  font-size: 26pt;
  margin-bottom: 10px;
}

.tournament-rules-container li {
  font-size: 14pt;
  margin-bottom: 2px;
  word-wrap: break-word;
}

.create-bracket-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 25px 0px;
  background-color: var(--naples-yellow);
}

.create-bracket-link {
  border-radius: 10px;
  border: 2px solid var(--fluorescent-cyan);
  padding: 20px;
  font-size: 30pt;
  text-align: center;
  text-decoration: none;
  background-color: var(--bittersweet);
  color: var(--maize);
  transition: cubic-bezier(1, 0, 0, 1) 0.3s;
}

.create-bracket-link:hover {
  background-color: var(--maize);
  color: var(--bittersweet);
  border: 2px solid var(--bittersweet);
}

.bracket-link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  /* overflow: scroll; */
}

.bracket-link-container h1 {
  font-size: 30pt;
  color: var(--bittersweet);
  margin-bottom: 10px;
  border-bottom: 2px solid var(--bittersweet);
}

.bracket-link {
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-decoration: none;
  background-color: var(--bittersweet);
  color: var(--maize);
  margin-top: 20px;
  border-radius: 10px;
  transition: cubic-bezier(1, 0, 0, 1) 0.3s;
  border: 2px solid var(--maize);
}

.bracket-link:hover {
  background-color: var(--maize);
  color: var(--bittersweet);
  border: 2px solid var(--bittersweet);
}

.bracket-link-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.hall-of-fame-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 25px 0px;
  background-color: var(--naples-yellow);
}

.hall-of-fame-container h1 {
  font-size: 30pt;
  color: var(--bittersweet);
  margin-bottom: 30px;
  border-bottom: 2px solid var(--bittersweet);
}

.hall-of-fame-entry-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.hall-entry {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 2px solid var(--fluorescent-cyan);
  padding: 10px;
  background-color: var(--bittersweet);
  color: var(--maize);
  text-align: center;
  font-weight: bold;
  transition: cubic-bezier(1, 0, 0, 1) 0.3s;
}

.hall-entry p.pacifico-regular {
  font-size: 20pt;
}

.trophy {
  font-size: 30pt;
  margin-top: 10px;
}

.hall-entry:hover {
  background-color: var(--maize);
  color: var(--bittersweet);
  border: 2px solid var(--bittersweet);
}

.bracket-display {
  background: var(--bittersweet);
}

.bracket-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  background-color: var(--naples-yellow);
  color: var(--bittersweet);
  border-bottom: 2px solid var(--fluorescent-cyan);
  font-weight: bold;
  font-size: 14pt;
  width: 100%;
}

.bracket-header h1 {
  margin-left: 25px;
  margin-right: auto;
}

.round-title {
  color: var(--naples-yellow);
  text-decoration: underline;
}

.round-title-builder {
  color: var(--bittersweet);
  text-decoration: underline;
}

.custom-bracket {
  margin-top: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.bracket-builder {
  background-color: var(--fluorescent-cyan);
}

.bracket-builder h1 {
  font-size: 30pt;
  color: var(--bittersweet);
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  background-color: var(--naples-yellow);
}

.bracket-builder p {
  background-color: var(--bittersweet);
  color: var(--maize);
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-size: 16pt;
  padding: 10px 0px;
  border-bottom: 2px solid var(--bittersweet);
}

.bracket-name {
  background-color: var(--naples-yellow);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 25px 0px;
}

.bracket-name input {
  margin: 0px 10px;
}

.bracket-name input[type="text"] {
  font-size: 16pt;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid var(--bittersweet);
  background-color: var(--honeydew);
  color: var(--bittersweet);
  transition: cubic-bezier(1, 0, 0, 1) 0.3s;
}

.bracket-name input[type="text"]:focus {
  background-color: var(--bittersweet);
  color: var(--honeydew);
  border: 2px solid var(--naples-yellow);
}

.bracket-name input[type="button"] {
  cursor: pointer;
  font-size: 16pt;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid var(--fluorescent-cyan);
  background-color: var(--bittersweet);
  color: var(--honeydew);
  transition: cubic-bezier(1, 0, 0, 1) 0.3s;
}

.bracket-name input[type="button"]:hover {
  background-color: var(--honeydew);
  color: var(--bittersweet);
  border: 2px solid var(--bittersweet);
}

.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--bittersweet);
}

.login-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.login-container h1 {
  font-size: 30pt;
  color: var(--honeydew);
  margin-bottom: 10px;
}

.login-container input[type="username"],
.login-container input[type="password"],
.login-container input[type="email"],
.login-container input[type="text"] {
  font-size: 14pt;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 2px solid var(--naples-yellow);
  background-color: var(--honeydew);
  color: var(--bittersweet);
  transition: cubic-bezier(1, 0, 0, 1) 0.3s;
}

.login-container button {
  cursor: pointer;
  font-size: 14pt;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid var(--maize);
  background-color: var(--bittersweet);
  color: var(--honeydew);
  transition: cubic-bezier(1, 0, 0, 1) 0.3s;
}

.login-container button:hover {
  background-color: var(--honeydew);
  color: var(--bittersweet);
  border: 2px solid var(--naples-yellow);
}

.switch-signup {
  color: var(--honeydew);
}

nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  background-color: var(--fluorescent-cyan);
  border-bottom: 2px solid var(--bittersweet);
  color: var(--bittersweet);
  font-weight: bold;
  font-size: 14pt;
}

.nav-link {
  text-decoration: none;
  color: var(--bittersweet);
  transition: cubic-bezier(1, 0, 0, 1) 0.3s;
  padding: 0px 20px;
  font-size: 16pt;
}

nav div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

nav p {
  padding: 0px 10px;
}

nav button {
  cursor: pointer;
  font-size: 12pt;
  padding: 5px;
  margin: 0px 10px;
  border-radius: 10px;
  border: 2px solid var(--maize);
  background-color: var(--bittersweet);
  color: var(--honeydew);
  transition: cubic-bezier(1, 0, 0, 1) 0.3s;
}

nav button:hover {
  background-color: var(--fluorescent-cyan);
  color: var(--bittersweet);
  border: 2px solid var(--naples-yellow);
}

footer {
  width: 100%;
  background-color: var(--bittersweet);
  border-top: 2px solid var(--maize);
}

footer p {
  text-align: right;
  color: var(--naples-yellow);
  font-weight: bold;
  margin-right: 10px;
  padding: 10px 0px;
}

@media screen and (max-width: 1000px) {
  .bracket-list-heading {
    font-size: 50pt;
  }

  .create-bracket-link {
    font-size: 20pt;
  }

  .nav-link {
    font-size: 12pt;
  }
}
